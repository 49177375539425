import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import NumericalInput from '../Utility/NumericalInput/NumericalInput';
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

const BallroomCalculator = ({ ballroomPoints, setBallroomPoints }) => {
    const [charm, setCharm] = useState(0);
    const [invites, setInvites] = useState(0);
    const [bonus, setBonus] = useState(0);

    useEffect(() => {
        const loverPower = 67 * charm * (100 + Number(charm)) / 1000;
        setBallroomPoints(Math.floor(loverPower * invites * ((100 + Number(bonus)) / 100)));
    }, [charm, invites, bonus]);

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Calculate lover points</Popover.Header>
            <Popover.Body>
                <p>Use this section to calculate how much lover points you can get from ballroom dances.</p>
                <p>Enter a lover&apos;s charm, how many ballroom invites you plan to use and that lover&apos;s bonus (which you get from lover decor).
                    For lover decor bonus, enter 1 if the bonus is 1%.</p>
                <p>The result is the amount of lover points you should get!</p>
            </Popover.Body>
        </Popover>
    );

    return (
        <div className="grid-item rounded-3">
            <div className="wrap" id="directTalentDiv">
                <div className="flex">
                    <span className="grow">Ballroom Invite Calculator</span>
                    <OverlayTrigger trigger="click" overlay={popover} placement="bottom" rootClose>
                        <Button variant="outline-secondary">What&apos;s this?</Button>
                    </OverlayTrigger>
                </div>
                <div className="d-flex mt-05 mb-075">
                    <NumericalInput
                        label="Lover's charm"
                        placeholder="Charm"
                        id="loverCharm"
                        value={Number(charm).toString()}
                        setValue={setCharm}
                        inputClass="width-15" />
                    <NumericalInput
                        label="Ballroom invites"
                        placeholder="Invites"
                        id="ballInvites"
                        value={Number(invites).toString()}
                        setValue={setInvites}
                        inputClass="width-15" />
                    <NumericalInput
                        label="Lover decor bonus"
                        placeholder="Bonus %"
                        id="loverBonus"
                        value={Number(bonus).toString()}
                        setValue={setBonus}
                        inputClass="width-15" />
                </div>
                <span>Lover points:&nbsp;<span id="loverPoints">{ballroomPoints.toLocaleString()}&#10084;</span></span>
            </div>
        </div>
    )
};

BallroomCalculator.propTypes = {
    ballroomPoints: PropTypes.number,
    setBallroomPoints: PropTypes.func
}

export default BallroomCalculator;