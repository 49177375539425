import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import serverUrl from "constants";

const AddFriendModal = ({ show, handleClose }) => {
    const [formValue, setFormValue] = useState({ email: '', name: '' });

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }

    const handleEdit = async (e) => {
        e.preventDefault();

        const formData = { email: formValue.email, name: formValue.name };
        await axios.put(serverUrl + "friends.php", formData);
        handleClose();
    };

    return (
        <Modal size="sm" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Make friends</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="editForm.name">
                        <Form.Control
                            placeholder="Friend's name"
                            name="name"
                            onChange={handleInput}
                            value={formValue.name}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="editForm.email">
                        <Form.Control
                            placeholder="Friend's KC Planner email"
                            name="email"
                            onChange={handleInput}
                            value={formValue.email}
                            autoFocus
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEdit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

AddFriendModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func
}

export default AddFriendModal;