
import React, { useState } from "react";
import SidePanel from '../SidePanel/SidePanel';
import ResourceList from './ResourceList';
import CurrentResources from './CurrentResources';
import './Resources.css';
import axios from 'axios';
import serverUrl from "constants";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

const Resources = () => {
    const [currentAccount, setCurrentAccount] = useState(null);

    const updateResources = async () => {
        const curAccData = await fetch(serverUrl + "current_account.php");
        const curAccRes = await curAccData.json();
        const accountData = { accountid: curAccRes.data };
        const res = await axios.get(serverUrl + "accounts.php", { params: accountData });
        if (res?.data.status == "SUCCESS") {
            setCurrentAccount(res.data.data[0]);
        }
    };

    const setAccountAndUpgrade = (value) => {
        setCurrentAccount(value);
        updateResources();
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Manage resources</Popover.Header>
            <Popover.Body>
                <p>Add and consult your resources for each category. This information will be used for your to-do list to determine whether you can complete each item.</p>
                <p>For silver, you can also get your potential silver gain from collection decrees (in Interior Ministry) and taxes (in your Bag) based
                    on your total intelligence. You can see your total intelligence in the Interior Ministry.</p>
                <p>You can also estimate how much silver you will have accumulated from the Interior Ministry by a certain date
                    if it gets collected automatically.</p>
                <p>For books, you can see how much State Power you would gain from using all your books. </p>
            </Popover.Body>
        </Popover>
    );
    return (
        <div className="d-flex flex-row">
            <SidePanel activePage={"Resources"} onChange={setAccountAndUpgrade} />
            <div className="d-flex flex-column padding-10 w-100 scroll">
                {!currentAccount && <div><b>&#8601;</b> Add an account to see and add ressources.</div>}
                <div className="whats-this">
                    <OverlayTrigger trigger="click" overlay={popover} placement="bottom" rootClose>
                        <Button variant="outline-secondary" >What&apos;s this?</Button>
                    </OverlayTrigger>
                </div>
                {currentAccount && <ResourceList currentAccount={currentAccount} onEdit={updateResources} />}
                {currentAccount && <CurrentResources currentAccount={currentAccount} />}
            </div>
        </div>
    );
};

export default Resources;