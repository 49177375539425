import React from "react";
import PropTypes from 'prop-types';

const KnightSelection = ({ setKnight, knights }) => {
    return (
        <div className="mr-1 grow select-knight">
            <div className="header">Select a knight</div>
            <select
                className="rounded-3 mb-3 calc-select"
                id="selectedKnight"
                onChange={(e) => setKnight(e.currentTarget.value)}>
                <option className="hidden">Select a knight</option>
                {knights?.sort((a, b) => a.name.localeCompare(b.name)).map((knight) =>
                    <option key={knight.name}>{knight.name}</option>
                )}
            </select>
        </div>
    )
}

KnightSelection.propTypes = {
    setKnight: PropTypes.func,
    knights: PropTypes.arrayOf(PropTypes.object)
}

export default KnightSelection;