import React, { useState } from 'react';
import './yeti-login.css';
import Yeti from './Yeti';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Form, FloatingLabel } from 'react-bootstrap';
import serverUrl from "constants";

function Login() {
    const [showPasswordCheck, setShowPasswordCheck] = useState(false);
    const [createAccount, setCreateAccount] = useState(false);
    const [formValue, setFormValue] = useState({ email: '', password: '' });

    const onCreateAccountChange = (event) => {
        const { checked } = event.target;
        setCreateAccount(checked);
    };

    const onShowPasswordChange = (event) => {
        const { checked } = event.target;
        setShowPasswordCheck(checked);
    };

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }

    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();

        let res = {};
        if (formValue.email === "" || formValue.password === "") {
            alert("I think you forgot something...");
            return;
        }

        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formValue.email)) {
            alert("Do you even know what an email is??");
            return;
        }

        if (createAccount) {
            const formData = { email: formValue.email, password: formValue.password };
            res = await axios.post(serverUrl + "users.php", formData);
        }
        else {
            const formData = { email: formValue.email, password: formValue.password };
            res = await axios.get(serverUrl + "users.php", { params: formData });
        }

        if (res.data.status == "Success") {
            navigate("/knights");
        }
        else if (res.data.message == "Incorrect password")
            alert("Wrong password (: \nDo better.");
        else if (res.data.message == "Account not found")
            alert("This account does not exist. Did you want to create a new account? If so, check the Create Account option :)))");
        else if (res.data.message == "Email already taken")
            alert("This email is already used. Did you forget you made an account already? Silly!");
        else
            alert("Something went wrong. Please try again.");
    };

    return (
        <>
            <div className="login">
                <div className="login-intro">
                    <h4>Welcome to <b>King&apos;s Planner</b>!</h4><br />
                    <i>This website was designed to help you <b>plan your knight building</b> for the mobile game King&apos;s Choice.<br /><br />
                        Create an account, register one or many <b>in-game accounts</b>, add your <b>knights</b>, calculate future <b>State Power</b> growth and
                        estimate the <b>resources</b> you&apos;ll need.
                        Save things you want to do in a <b>To-Do list</b> and check them off as you go!</i>
                </div>
                <form className="login-form" onSubmit={handleSubmit} id="loginForm">
                    <Yeti />
                    <div className="inputGroup inputGroup2">
                        <label className="toggle" id="createAccountToggle" htmlFor="createAccountCheck">New user? Create an account
                            <input
                                id="createAccountCheck"
                                type="checkbox"
                                checked={createAccount}
                                onChange={onCreateAccountChange} />
                            <div className="indicator"></div>
                        </label>
                    </div>
                    <div className="inputGroup ">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email address"
                            className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="name@domain.com"
                                name="email"
                                onChange={handleInput}
                                value={formValue.email} />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPassword" label="Password">
                            <Form.Control
                                type={showPasswordCheck ? "text" : "password"}
                                placeholder="Password"
                                name="password"
                                value={formValue.password}
                                onChange={handleInput} />
                        </FloatingLabel>
                    </div>
                    <div className="inputGroup inputGroup2 mt-2">
                        <label className="toggle" id="showPasswordToggle" htmlFor="showPasswordCheck">Show password
                            <input id="showPasswordCheck"
                                type="checkbox"
                                checked={showPasswordCheck}
                                onChange={onShowPasswordChange} />
                            <div className="indicator"></div>
                        </label>
                    </div>
                    <div className="inputGroup ">
                        <button id="login" type="submit">{createAccount ? "Sign up" : "Log in"}</button>
                    </div>
                </form>
            </div>
            <footer>This website was created by one nerd in her free time. Please be kind and understanding!<br />
                If you would like to report an issue or suggest improvements,
                you can send me an email at okiedokie0815@gmail.com</footer>
        </>
    );
}

export default Login;