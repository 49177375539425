import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import serverUrl from "constants";

const EditAuraItemsModal = ({ show, handleClose, currentAccount }) => {
    const [formValue, setFormValue] = useState({
        type: "aura",
        auraCrowns: currentAccount.auraCrowns,
        auraShields: currentAccount.auraShields,
        auraSwords: currentAccount.auraSwords,
        auraBooks: currentAccount.auraBooks,
        auraHelmets: currentAccount.auraHelmets,
        auraCoins: currentAccount.auraCoins,
        auraExchange: currentAccount.auraExchange
    });

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                type: "aura",
                auraCrowns: currentAccount.auraCrowns,
                auraShields: currentAccount.auraShields,
                auraSwords: currentAccount.auraSwords,
                auraBooks: currentAccount.auraBooks,
                auraHelmets: currentAccount.auraHelmets,
                auraCoins: currentAccount.auraCoins,
                auraExchange: currentAccount.auraExchange
            });
        };
        resetForm();
    }, [show]);

    const handleEdit = async (e) => {
        e.preventDefault();
        await axios.put(serverUrl + "accounts.php", formValue);
        handleClose();
    };

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };
    return (
        <Modal backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update aura items</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Control
                                id="inputAuraCrowns"
                                placeholder="Crowns"
                                type="number"
                                name="auraCrowns"
                                value={formValue.auraCrowns}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current crowns: {currentAccount?.auraCrowns}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputAuraSwords"
                                placeholder="Swords"
                                type="number"
                                name="auraSwords"
                                value={formValue.auraSwords}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current swords: {currentAccount?.auraSwords}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputAuraShields"
                                placeholder="Shields"
                                type="number"
                                name="auraShields"
                                value={formValue.auraShields}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current shields: {currentAccount?.auraShields}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputAuraBooks"
                                placeholder="Books"
                                type="number"
                                name="auraBooks"
                                value={formValue.auraBooks}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current books: {currentAccount?.auraBooks}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputAuraHelmets"
                                placeholder="Helmets"
                                type="number"
                                name="auraHelmets"
                                value={formValue.auraHelmets}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current helmets: {currentAccount?.auraHelmets}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputAuraImprints"
                                placeholder="Imprints"
                                type="number"
                                name="auraCoins"
                                value={formValue.auraCoins}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current imprints: {currentAccount?.auraCoins}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputAuraExchange"
                                placeholder="Chests"
                                type="number"
                                name="auraExchange"
                                value={formValue.auraExchange}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current exchange chests: {currentAccount?.auraExchange}
                            </Form.Text>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEdit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditAuraItemsModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    currentAccount: PropTypes.object
}

export default EditAuraItemsModal;