import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import KnightModal from "./KnightModal";
import axios from 'axios';
import serverUrl from "constants";

const equipments = [
    { name: 'Baby', maxTalent: 50 },
    { name: 'Novice', maxTalent: 80 },
    { name: 'Iron', maxTalent: 120 },
    { name: 'Bronze', maxTalent: 160 },
    { name: 'Silver', maxTalent: 200 },
    { name: 'Gold', maxTalent: 250 },
    { name: 'Platinum', maxTalent: 300 },
    { name: 'Diamond', maxTalent: 350 },
];

const knightUpgMsg = [
    "But whyyy",
    "What a waste",
    "Are you ok?",
    "Is it worth it tho?",
    "Really? I mean... really??",
    "Press X to doubt",
    "**judges you in silence**"
];

const EditKnightModal = ({ show, handleClose, knightName, knights, dbKnight }) => {
    const [formValue, setFormValue] = useState({});
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                name: knightName,
                level: dbKnight?.level,
                saved_exp: dbKnight?.saved_exp,
                equipment: dbKnight?.equipment,
                strengthTalentLevels: dbKnight?.strengthTalentLevels,
                intelTalentLevels: dbKnight?.intelTalentLevels,
                leadershipTalentLevels: dbKnight?.leadershipTalentLevels,
                charismaTalentLevels: dbKnight?.charismaTalentLevels,
                strengthBonus: dbKnight?.strengthBonus,
                intelBonus: dbKnight?.intelBonus,
                leadershipBonus: dbKnight?.leadershipBonus,
                charismaBonus: dbKnight?.charismaBonus,
                decorTalent: dbKnight?.decorTalent,
                loverAttribute: dbKnight?.loverAttribute == "" ? "0,0" : dbKnight?.loverAttribute ?? "0,0",
                strengthBookAtt: dbKnight?.strengthBookAtt,
                intelBookAtt: dbKnight?.intelBookAtt,
                leadershipBookAtt: dbKnight?.leadershipBookAtt,
                charismaBookAtt: dbKnight?.charismaBookAtt,
                mountStr: dbKnight?.mountStr,
                mountIntel: dbKnight?.mountIntel,
                mountLead: dbKnight?.mountLead,
                mountChar: dbKnight?.mountChar,
                tier: dbKnight?.tier,
                loverPts: dbKnight?.loverPts,
                skillLvlHp: dbKnight?.skillLvlHp,
                skillLvlAttack: dbKnight?.skillLvlAttack
            });
            setValidated(false);
        };
        resetForm();
    }, [show]);

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        handleEdit(event);
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        const knightStar = knights.find(k => k.name === knightName).starCategory;
        const equipment = formValue.equipment;

        if (knightStar < 4 && equipment === "Diamond" && dbKnight.equipment !== "Diamond")
            alert("Two things are infinite: the universe and the stupidity of man. I'm not sure about the first, but you're definitely proving the latter.");
        else if (knightStar < 4 && equipment === "Platinum" && dbKnight.equipment !== "Platinum")
            alert(knightUpgMsg[Math.floor(Math.random() * knightUpgMsg.length)]);
        else {
            const maxTalent = equipments.find(equ => equ.name === equipment)?.maxTalent;
            const strTalents = formValue.strengthTalentLevels.split(",");
            const intelTalents = formValue.intelTalentLevels.split(",");
            const leadTalents = formValue.leadershipTalentLevels.split(",");
            const charTalents = formValue.charismaTalentLevels.split(",");
            const baseMsg = "Talent level shouldn't be this high for " + equipment + " equipment. The maximum value is " + maxTalent;

            if (strTalents.find(t => t > maxTalent))
                alert(baseMsg + ", but you do you!")
            else if (intelTalents.find(t => t > maxTalent))
                alert(baseMsg + ", but maybe you have a decor or negotiation bonus, what do I know..")
            else if (leadTalents.find(t => t > maxTalent))
                alert(baseMsg + ". But I'll let it slide just this once ;)")
            else if (charTalents.find(t => t > maxTalent))
                alert(baseMsg + ". But I'm in a good mood, so you can have a lil extra. As a treat ✨")
        }

        await axios.put(serverUrl + "knights.php", formValue);
        handleClose();
    };
    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit {knightName}?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <KnightModal knightName={knightName} knights={knights} currentKnight={dbKnight} formValue={formValue} setFormValue={setFormValue} />
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

EditKnightModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    knightName: PropTypes.string,
    knights: PropTypes.array,
    dbKnight: PropTypes.object
}

export default EditKnightModal;