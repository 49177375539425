import React, { useState } from "react";
import PropTypes from 'prop-types';
import EditTodoModal from './Modal/EditTodoModal';
import DeleteTodoModal from './Modal/DeleteTodoModal';
import ApplyChangesModal from './Modal/ApplyChangesModal';
import PreviewModal from './Modal/PreviewModal';
import axios from "axios";
import serverUrl from "constants";
import TalentTodoItem from "./Items/TalentTodoItem";
import EquipmentTodoItem from "./Items/EquipmentTodoItem";
import LevelTodoItem from "./Items/LevelTodoItem";
import BonusTodoItem from "./Items/BonusTodoItem";
import BookTodoItem from "./Items/BookTodoItem";
import NegotiationTodoItem from "./Items/NegotiationTodoItem";

const TodoItem = (
    {
        todo,
        completed,
        hasLover,
        onUpdateTodos,
        onSelect,
        selectedTodos,
        dbKnight,
        currentAccount
    }) => {
    const knightName = todo.todo?.knight;
    const type = todo.todo?.targetType;

    const handleSetCompleted = async () => {
        //e.preventDefault();

        const formData = { isCompleted: !todo.todo.isCompleted, todoId: todo.todo.id };
        await axios.put(serverUrl + "todos.php", formData);
        setShowApplyChanges(false);
        setShowPreview(false);
        onUpdateTodos();
    }

    const [showPreview, setShowPreview] = useState(false);
    const [showApplyChanges, setShowApplyChanges] = useState(false);
    const handleShowApplyChanges = () => {
        if (completed)
            handleSetCompleted();
        else
            setShowApplyChanges(true);
    }

    const [showDeleteTodo, setShowDeleteTodo] = useState(false);
    const handleCloseDelete = () => {
        setShowDeleteTodo(false);
        onUpdateTodos();
    }

    const [showEditTodo, setShowEditTodo] = useState(false);
    const handleCloseEdit = () => {
        setShowEditTodo(false);
        onUpdateTodos();
    }

    return (
        <>
            <svg className="d-none">
                <symbol id="edit" viewBox="0 0 16 16">
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                </symbol>
                <symbol id="delete" viewBox="0 0 16 16">
                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                </symbol>
                <symbol id="complete" viewBox="0 0 14 14">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </symbol>
                <symbol id="happy" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8" />
                </symbol>
                <symbol id="sad" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-2.715 5.933a.5.5 0 0 1-.183-.683A4.5 4.5 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.5 3.5 0 0 0 8 10.5a3.5 3.5 0 0 0-3.032 1.75.5.5 0 0 1-.683.183M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8" />
                </symbol>
            </svg>
            <label className="list-group-item rounded-3" htmlFor="listGroupCheckableRadios1" onClick={() => { if (!completed) onSelect(todo.todo.id); }}>
                <span className="d-flex align-items-center">
                    {!completed && <input type="checkbox" className="mr-1" value={todo.todo.id} onChange={() => onSelect(todo.todo.id)} checked={selectedTodos.includes(todo.todo.id)} />}
                    <span className="mt-1 mb-1 d-flex grow align-items-center">
                        {type == "Talent" && <TalentTodoItem todo={todo} completed={completed} />}
                        {type == "Bonus" && <BonusTodoItem todo={todo} completed={completed} hasLover={hasLover} />}
                        {type == "Book Attribute" && <BookTodoItem todo={todo} completed={completed} />}
                        {type == "Negotiation Level" && <NegotiationTodoItem todo={todo} completed={completed} />}
                        {type == "Level" && <LevelTodoItem todo={todo} completed={completed} />}
                        {type == "Equipment" && <EquipmentTodoItem todo={todo} completed={completed} />}
                    </span>
                    {
                        <div>
                            {!completed && <>
                                {todo.doable && <span title="Enough resources!">
                                    <svg className="bi pe-none opacity-75 doable" width="16" height="16"><use xlinkHref="#happy" /></svg>
                                </span>}
                                {!todo.doable && <span title="Not enough resources...">
                                    <svg className="bi pe-none opacity-75 not-doable" width="16" height="16"><use xlinkHref="#sad" /></svg>
                                </span>}
                            </>}
                            <button className="btn btn-icon opacity-75" onClick={handleShowApplyChanges} title="Complete">
                                <svg className="bi pe-none" width="24" height="24"><use xlinkHref="#complete" /></svg>
                            </button>
                            {!completed && <>
                                <button className="btn btn-icon opacity-75" onClick={() => setShowEditTodo(true)} title="Edit">
                                    <svg className="bi pe-none" width="16" height="16"><use xlinkHref="#edit" /></svg>
                                </button>
                            </>}
                            <button className="btn btn-icon opacity-75" onClick={() => setShowDeleteTodo(true)} title="Delete">
                                <svg className="bi pe-none" width="16" height="16"><use xlinkHref="#delete" /></svg>
                            </button>
                        </div>
                    }
                </span>
            </label>
            {todo.todo && <EditTodoModal show={showEditTodo} handleClose={handleCloseEdit} knightName={knightName} upgradeType={type} hasLover={hasLover} todo={todo.todo} />}
            {todo.todo && <DeleteTodoModal show={showDeleteTodo} handleClose={handleCloseDelete} todoId={todo.todo?.id} />}
            {todo.todo && <ApplyChangesModal show={showApplyChanges} handleComplete={handleSetCompleted} handleClose={() => setShowApplyChanges(false)} handlePreview={() => setShowPreview(true)} />}
            {todo.todo && <PreviewModal show={showPreview} handleComplete={handleSetCompleted} todo={todo.todo} dbKnight={dbKnight} currentAccount={currentAccount} />}
        </>
    )
}

TodoItem.propTypes = {
    todo: PropTypes.object,
    completed: PropTypes.bool,
    hasLover: PropTypes.bool,
    onUpdateTodos: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedTodos: PropTypes.array,
    dbKnight: PropTypes.object,
    currentAccount: PropTypes.object
}

export default TodoItem;