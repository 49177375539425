import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import KnightModal from "./KnightModal";
import axios from 'axios';
import serverUrl from "constants";
import { OverlayTrigger, Popover } from "react-bootstrap";

const equipments = [
    { name: 'Baby', maxTalent: 50 },
    { name: 'Novice', maxTalent: 80 },
    { name: 'Iron', maxTalent: 120 },
    { name: 'Bronze', maxTalent: 160 },
    { name: 'Silver', maxTalent: 200 },
    { name: 'Gold', maxTalent: 250 },
    { name: 'Platinum', maxTalent: 300 },
    { name: 'Diamond', maxTalent: 350 },
];

const knightUpgMsg = [
    "But whyyy",
    "What a waste",
    "Are you ok?",
    "Is it worth it tho?",
    "Really? I mean... really??",
    "Press X to doubt",
    "**judges you in silence**"
];

const AddKnightModal = ({ show, handleClose, knights, currentKnights }) => {
    const [formValue, setFormValue] = useState({});
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                name: '',
                level: '',
                saved_exp: '',
                equipment: '',
                strengthTalentLevels: '',
                intelTalentLevels: '',
                leadershipTalentLevels: '',
                charismaTalentLevels: '',
                strengthBonus: '',
                intelBonus: '',
                leadershipBonus: '',
                charismaBonus: '',
                decorTalent: '0,;0,',
                loverAttribute: '0,0',
                strengthBookAtt: '',
                intelBookAtt: '',
                leadershipBookAtt: '',
                charismaBookAtt: '',
                mountStr: '',
                mountIntel: '',
                mountLead: '',
                mountChar: '',
                tier: '',
                loverPts: '',
                skillLvlHp: '1',
                skillLvlAttack: '1'
            });
            setValidated(false);
        };
        resetForm();
    }, [show]);

    const handleAdd = async (e) => {
        e.preventDefault();
        const knightStar = knights.find(k => k.name === formValue.name).starCategory;
        const equipment = formValue.equipment;

        if (knightStar < 4 && equipment === "Diamond")
            alert("Two things are infinite: the universe and the stupidity of man. I'm not sure about the first, but you're definitely proving the latter.");
        else if (knightStar < 4 && equipment === "Platinum")
            alert(knightUpgMsg[Math.floor(Math.random() * knightUpgMsg.length)]);
        else {
            const maxTalent = equipments.find(equ => equ.name === equipment)?.maxTalent;
            const strTalents = formValue.strengthTalentLevels.split(",");
            const intelTalents = formValue.intelTalentLevels.split(",");
            const leadTalents = formValue.leadershipTalentLevels.split(",");
            const charTalents = formValue.charismaTalentLevels.split(",");
            const baseMsg = "Talent level shouldn't be this high for " + equipment + " equipment. The maximum value is " + maxTalent;

            if (strTalents.find(t => t > maxTalent))
                alert(baseMsg + ", but you do you!")
            else if (intelTalents.find(t => t > maxTalent))
                alert(baseMsg + ", but maybe you have a decor or negotiation bonus, what do I know..")
            else if (leadTalents.find(t => t > maxTalent))
                alert(baseMsg + ". But I'll let it slide just this once ;)")
            else if (charTalents.find(t => t > maxTalent))
                alert(baseMsg + ". But I'm in a good mood, so you can have a lil extra. As a treat ✨")
        }

        await axios.post(serverUrl + "knights.php", formValue);
        handleClose();

    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        handleAdd(event);
    };

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Enter knight information here</Popover.Header>
            <Popover.Body>
                <p>Select a knight you haven&apos;t added yet, then select its equipment and adjust the level as needed.</p>
                <p>Watch out for fields that require a <b>level</b> instead of the value associated with that level.</p>
                <p>Ex: for talent, enter the level (10) instead of the talent points it gives (60). For bonus, enter the value directly (80%).</p>
            </Popover.Body>
        </Popover>
    );

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="me-3">New knight</Modal.Title>
                <OverlayTrigger trigger="click" overlay={popover} placement="bottom" rootClose>
                    <Button variant="outline-secondary" >What&apos;s this?</Button>
                </OverlayTrigger>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <KnightModal knights={knights} currentKnights={currentKnights} formValue={formValue} setFormValue={setFormValue} />
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Add
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

AddKnightModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    knights: PropTypes.array,
    currentKnights: PropTypes.arrayOf(PropTypes.string)
}

export default AddKnightModal;