
import React, { useState } from "react";
import PropTypes from "prop-types";
import SilverIcon from './Icons/SilverIcon';
import EdictIcon from './Icons/EdictIcon';
import NegotiationIcon from './Icons/NegotiationIcon';
import EquipmentIcon from './Icons/EquipmentIcon';
import AuraIcon from './Icons/AuraIcon';
import ExpIcon from './Icons/ExpIcon';
import BookIcon from './Icons/BookIcon';
import EditSilverModal from './Modal/EditSilverModal';
import EditEdictsModal from './Modal/EditEdictsModal';
import EditExpModal from './Modal/EditExpModal';
import EditBooksModal from './Modal/EditBooksModal';
import EditAuraItemsModal from './Modal/EditAuraItemsModal';
import EditEquipmentModal from './Modal/EditEquipmentModal';
import EditNegotiationModal from './Modal/EditNegotiationModal';

const ResourceList = ({ currentAccount, onEdit }) => {
    let iconButtonClassName = "d-flex flex-column grow align-items-center btn btn-icon";

    const [showEditSilver, setShowEditSilver] = useState(false);
    const handleCloseSilver = () => {
        setShowEditSilver(false);
        onEdit();
    }
    const handleShowSilver = () => setShowEditSilver(true);

    const [showEditEdicts, setShowEditEdicts] = useState(false);
    const handleCloseEdicts = () => {
        setShowEditEdicts(false);
        onEdit();
    }
    const handleShowEdicts = () => setShowEditEdicts(true);

    const [showEditExp, setShowEditExp] = useState(false);
    const handleCloseExp = () => {
        setShowEditExp(false);
        onEdit();
    }
    const handleShowExp = () => setShowEditExp(true);

    const [showEditBooks, setShowEditBooks] = useState(false);
    const handleCloseBooks = () => {
        setShowEditBooks(false);
        onEdit();
    }
    const handleShowBooks = () => setShowEditBooks(true);

    const [showEditAuraItems, setShowEditAuraItems] = useState(false);
    const handleCloseAuraItems = () => {
        setShowEditAuraItems(false);
        onEdit();
    }
    const handleShowAuraItems = () => setShowEditAuraItems(true);

    const [showEditEquipment, setShowEditEquipment] = useState(false);
    const handleCloseEquipment = () => {
        setShowEditEquipment(false);
        onEdit();
    }
    const handleShowEquipment = () => setShowEditEquipment(true);

    const [showEditNegotiation, setShowEditNegotiation] = useState(false);
    const handleCloseNegotiation = () => {
        setShowEditNegotiation(false);
        onEdit();
    }
    const handleShowNegotiation = () => setShowEditNegotiation(true);

    return (
        <div className="d-flex flex-column padding-10 w-100">
            <div className="d-flex mt-3 ml-2">
                <button className={iconButtonClassName} onClick={handleShowSilver} id="editSilverBtn" title="Edit silver">
                    <SilverIcon />
                    <span>Silver</span>
                </button>
                <button className={iconButtonClassName} onClick={handleShowEdicts} id="editEdictsBtn" title="Edit edicts">
                    <EdictIcon />
                    <span>Edicts</span>
                </button>
                <button className={iconButtonClassName} onClick={handleShowExp} id="editExpBtn" title="Edit EXP scrolls">
                    <ExpIcon />
                    <span>EXP Scrolls</span>
                </button>
                <button className={iconButtonClassName} onClick={handleShowBooks} id="editBooksBtn" title="Edit books">
                    <BookIcon />
                    <span>Books</span>
                </button>
                <button className={iconButtonClassName} onClick={handleShowAuraItems} id="editAuraBtn" title="Edit aura items">
                    <AuraIcon />
                    <span>Aura items</span>
                </button>
                <button className={iconButtonClassName} onClick={handleShowEquipment} id="editEquipmentBtn" title="Edit equipment">
                    <EquipmentIcon />
                    <span>Equipment</span>
                </button>
                <button className={iconButtonClassName} onClick={handleShowNegotiation} id="editNegotiationBtn" title="Edit negotiation points">
                    <NegotiationIcon />
                    <span>Negotiation</span>
                </button>
            </div>

            {currentAccount && <EditSilverModal show={showEditSilver} handleClose={handleCloseSilver} currentAccount={currentAccount} />}
            {currentAccount && <EditEdictsModal show={showEditEdicts} handleClose={handleCloseEdicts} currentAccount={currentAccount} />}
            {currentAccount && <EditExpModal
                show={showEditExp}
                handleClose={handleCloseExp}
                current50Exp={currentAccount?.basicExpScrolls.toString()}
                current100Exp={currentAccount?.advExpScrolls.toString()}
                current500Exp={currentAccount && currentAccount['500ExpScrolls'].toString()} />}
            {currentAccount && <EditBooksModal show={showEditBooks} handleClose={handleCloseBooks} currentAccount={currentAccount} />}
            {currentAccount && <EditAuraItemsModal show={showEditAuraItems} handleClose={handleCloseAuraItems} currentAccount={currentAccount} />}
            {currentAccount && <EditEquipmentModal show={showEditEquipment} handleClose={handleCloseEquipment} currentAccount={currentAccount} />}
            {currentAccount && <EditNegotiationModal show={showEditNegotiation} handleClose={handleCloseNegotiation} currentPoints={currentAccount?.negotiationPts.toString()} />}
        </div>
    );
};

ResourceList.propTypes = {
    currentAccount: PropTypes.object,
    onEdit: PropTypes.func
}

export default ResourceList;