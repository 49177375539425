import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import NegotiationTodo from './NegotiationTodo';
import LevelTodo from './LevelTodo';
import TalentTodo from './TalentTodo';
import BonusTodo from './BonusTodo';
import BookTodo from './BookTodo';
import EquipmentTodo from './EquipmentTodo';
import axios from "axios";
import serverUrl from "constants";
import knights from '../../../data/knights.json';

const upgradeTypes = ['Talent', 'Bonus', 'Level', 'Book Attribute', 'Equipment'];

const EditTodoModal = ({ show, handleClose, knightName, upgradeType, todo, hasLover, hasBonus, addFromPreview }) => {
    const type = upgradeType?.charAt(0).toUpperCase() + upgradeType?.slice(1);
    const [formValue, setFormValue] = useState({
        id: todo.id,
        isCompleted: false,
        knight: todo.knight,
        targetAtt: todo.targetAtt,
        targetAtt2: todo.targetAtt2,
        targetType: todo.targetType,
        upgradeValue: todo.upgradeValue,
        upgradeResource: todo.upgradeResource,
        upgradeCost: todo.upgradeCost,
        upgradeTarget: todo.upgradeTarget,
        upgradeBookCost: todo.upgradeBookCost ? todo.upgradeBookCost : ",,,,",
        upgradeEquipTarget: todo.upgradeEquipTarget,
        targetOption: todo.targetOption,
        note: todo.note,
        upgradeMethod: todo.upgradeCost > 0 || (todo.upgradeBookCost && todo.upgradeBookCost != ",,,,") ? "using" : todo.upgradeTarget > 0 ? "to" : "by"
    });
    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                id: todo.id,
                isCompleted: false,
                knight: todo.knight,
                targetAtt: todo.targetAtt,
                targetAtt2: todo.targetAtt2,
                targetType: todo.targetType,
                upgradeValue: todo.upgradeValue,
                upgradeResource: todo.upgradeResource,
                upgradeCost: todo.upgradeCost,
                upgradeTarget: todo.upgradeTarget,
                upgradeBookCost: todo.upgradeBookCost ? todo.upgradeBookCost : ",,,,",
                upgradeEquipTarget: todo.upgradeEquipTarget,
                targetOption: todo.targetOption,
                note: todo.note,
                upgradeMethod: todo.upgradeCost > 0 || (todo.upgradeBookCost && todo.upgradeBookCost != ",,,,") ? "using" : todo.upgradeTarget > 0 ? "to" : "by"
            });
        };
        resetForm();
    }, [show, todo]);

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };
    const handleEdit = async (e) => {
        e.preventDefault();
        await axios.put(serverUrl + "todos.php", formValue);
        handleClose();
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        if (formValue.targetType == "Equipment") {
            if (formValue.upgradeEquipTarget == "")
                return;
        }
        else if ((Number(formValue.upgradeCost) + Number(formValue.upgradeTarget) + Number(formValue.upgradeValue)) <= 0)
            return;
        if (formValue.targetType == "Bonus" && formValue.targetOption === "")
            formValue.targetOption = null;
        await axios.post(serverUrl + "todos.php", formValue);
        //console.log(res);
        handleClose();
    };

    return (
        <Modal backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit to-do item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Label>Editing to-do for:</Form.Label>
                    <br />
                    <ButtonGroup className="mb-3">
                        <ToggleButton
                            disabled
                            key={0}
                            id={`radio-0`}
                            type="radio"
                            variant="outline-secondary"
                            name="radio"
                            value="0"
                            checked={knightName}>
                            Knight
                        </ToggleButton>
                        <ToggleButton
                            disabled
                            key={1}
                            id={`radio-1`}
                            type="radio"
                            variant="outline-secondary"
                            name="radio"
                            value="1"
                            checked={!knightName}>
                            Negotiation decree
                        </ToggleButton>
                    </ButtonGroup>
                    <br />
                    {upgradeType == "Negotiation Level" &&
                        <>
                            <NegotiationTodo formValue={formValue} setFormValue={setFormValue} />
                        </>
                    }
                    {upgradeType != "Negotiation Level" &&
                        <>
                            <Form.Select disabled>
                                <option>{knightName}</option>
                            </Form.Select>
                            <Form.Label className="mt-3">Upgrading:</Form.Label>
                            {!!type && <Form.Select disabled>
                                <option>{type}</option>
                            </Form.Select>}
                            {!type &&
                                <Form.Select
                                    name="targetType"
                                    onChange={handleInput}>
                                    <option hidden>Select type</option>
                                    {upgradeTypes.map((option, idx) => (
                                        ((hasBonus && option == "Bonus") || option != "Bonus") &&
                                        <option
                                            key={idx}
                                            id={`option-${idx}`}
                                            value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Select>}
                            {formValue.targetType == "Talent" && <TalentTodo formValue={formValue} setFormValue={setFormValue} />}
                            {formValue.targetType == "Bonus" &&
                                <BonusTodo
                                    hasLover={hasLover}
                                    knightTypes={knights.find(k => k.name === knightName).type}
                                    formValue={formValue}
                                    setFormValue={setFormValue} />}
                            {formValue.targetType == "Level" && <LevelTodo formValue={formValue} setFormValue={setFormValue} />}
                            {formValue.targetType == "Book Attribute" && <BookTodo formValue={formValue} setFormValue={setFormValue} />}
                            {formValue.targetType == "Equipment" && <EquipmentTodo isVisible={show} formValue={formValue} setFormValue={setFormValue} />}
                        </>
                    }
                    <Form.Label className="mt-3">Note to self:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        name="note"
                        defaultValue={Object(formValue).note}
                        onChange={handleInput}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary"
                    onClick={(e) => {
                        if (addFromPreview)
                            handleAdd(e);
                        else handleEdit(e);
                    }}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditTodoModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    knightName: PropTypes.string,
    knightTypes: PropTypes.string,
    upgradeType: PropTypes.string,
    hasLover: PropTypes.bool,
    hasBonus: PropTypes.bool,
    todo: PropTypes.object.isRequired,
    addFromPreview: PropTypes.bool
}

export default EditTodoModal;